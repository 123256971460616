export namespace FeatureFlag {
  export enum ID {
    CVC_REFRESH = 'cvc-refresh',
    SALES_CTA = 'hpanel-sales-cta',
    WEBPRO_CLIENT_CACHE_WARMUP = 'webpro-client-cache-warmup',
    WORDPRESS_AMAZON_AFFILIATES = 'wordpress-amazon-affiliates',
    NEW_WEBSITE_MIGRATIONS_UI = 'new-website-migrations-ui',
    WORDPRESS_PREBUILT_TEMPLATES_ONBOARDING = 'wp-pre-built-templates-in-onboarding',
    ONBOARDING_H5G = 'onboarding-h5g-flag',
    BE_SLOWDOWN_HANDLER = 'be-slowdown-handler',
    HPANEL_VERSION_UPDATE = 'hpanel-version-update',
    DASHBOARD_DOMAIN_STATUS = 'dashboard-domain-status',
    NEW_HELP_PAGE_SELECTION = 'new-help-page-selection',
    WEBSITES_PAGINATION = 'websites-pagination',
    GW_MDF_SALE = 'google-workspace-50-discount-hpanel-home-page',
  }

  export enum Value {
    ENABLED = 'on',
  }
}
