import { defineStore } from 'pinia';
import { ref, computed } from 'vue';

import { useGlobals } from '@/composables/useGlobals';
import reportRepo from '@/repositories/reportRepo';
import { getItemsPerPage } from '@/utils/services/paginationService';

const INITIAL_PAGE = 1;
const INITIAL_PER_PAGE_PAGE = 10;

export const useWebsiteReportsStore = defineStore('websiteReportsStore', () => {
  const { toastr, t } = useGlobals();

  const list = ref<Array<any> | null>(null);
  const totalListItems = ref(0);
  const isLoadingList = ref(false);
  const isDeleting = ref(false);
  const page = ref<number | null>(null);
  const perPage = ref<number | null>(null);

  const hasListBeenFetched = computed(() => list.value !== null);
  const hasGeneratedReports = computed(() => !!list.value?.length);

  const $reset = () => {
    list.value = null;
    totalListItems.value = 0;
    isLoadingList.value = false;
    isDeleting.value = false;
    page.value = null;
    perPage.value = null;
  };

  const fetchReportList = async (
    params: { page: number; perPage: number } = {
      page: INITIAL_PAGE,
      perPage: getItemsPerPage(),
    },
  ) => {
    isLoadingList.value = true;
    const [{ data }, error] = await reportRepo.getList(params);

    if (error) {
      isLoadingList.value = false;

      return;
    }
    list.value = data?.data;

    totalListItems.value = data.total;
    page.value = data.page;
    perPage.value = data.perPage;

    isLoadingList.value = false;
  };

  const deleteReport = async (id: string) => {
    isDeleting.value = true;
    const [_, error] = await reportRepo.deleteReport({ reportId: id });
    if (!error) {
      toastr.s(t('v2.report.delete.successfully'));
    }
    isDeleting.value = false;

    fetchReportList({
      page: page.value || INITIAL_PAGE,
      perPage: perPage.value || INITIAL_PER_PAGE_PAGE,
    });
  };

  return {
    list,
    hasListBeenFetched,
    hasGeneratedReports,
    totalListItems,
    isLoadingList,
    page,
    perPage,
    deleteReport,
    $reset,
    fetchReportList,
  };
});
